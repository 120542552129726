// We use contrast ratio 3 so the HAVIT blue has white text;
$min-contrast-ratio:  3;

$border-color:        $gray-200;
$border-color-translucent:    rgba($black, .0875);

$box-shadow:                  0 .5rem 1rem rgba(var(--#{$prefix}body-color-rgb), .1);
$box-shadow-sm:               0 .125rem .25rem rgba(var(--#{$prefix}body-color-rgb), .05);
$box-shadow-lg:               0 1rem 3rem rgba(var(--#{$prefix}body-color-rgb), .15);

$nav-tabs-border-color:               var(--#{$prefix}border-color-translucent);

$card-border-radius:                  var(--#{$prefix}border-radius-lg);
$card-inner-border-radius:            subtract($card-border-radius, $card-border-width);

$table-active-bg-factor:              .075;
$table-active-bg:                     rgba($black, $table-active-bg-factor);
$table-hover-bg-factor:               .05;
$table-hover-bg:                      rgba($black, $table-hover-bg-factor);

$form-label-margin-bottom:            .25rem;
$form-label-font-size:                .875rem;
$form-label-color:                    $gray-600;
$form-check-label-color:              $form-label-color;
$form-label-font-weight:              500;
$form-feedback-tooltip-font-size:     .75rem;
$form-feedback-margin-top:            .125rem;
$form-feedback-font-size:             .75rem;

$popover-header-bg:                   rgba(var(--#{$prefix}body-color-rgb), .03);

$modal-backdrop-opacity:              .25;

$offcanvas-horizontal-width:          500px;

$dropdown-box-shadow:               $box-shadow-sm;

$pagination-disabled-bg:            transparent;

$accordion-border-color:            var(--#{$prefix}border-color-translucent);

$toast-padding-x:                   1rem;
$toast-padding-y:                   .75rem;

$badge-font-weight:                 $font-weight-semibold;

$list-group-border-color:           var(--#{$prefix}border-color-translucent);

$hr-opacity: .125;
